<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { Paintbrush } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@/components/ui/dropdown-menu'
import { useTheme } from '@/composables/useTheme'

interface ColorTheme {
  name: string;
  value: string;
}

const isOpen = ref(false)
const { theme, setTheme } = useTheme()

const currentTheme = ref<string>(localStorage.getItem('user-theme-color') || 'blue')

const colorThemes: ColorTheme[] = [
  { name: 'Blue', value: 'blue' },
  { name: 'Zinc', value: 'zinc' },
  { name: 'Rose', value: 'rose' },
  { name: 'Green', value: 'green' },
  { name: 'Orange', value: 'orange' },
  { name: 'Red', value: 'red' },
  { name: 'Slate', value: 'slate' },
  { name: 'Stone', value: 'stone' },
  { name: 'Gray', value: 'gray' },
  { name: 'Neutral', value: 'neutral' },
  { name: 'Yellow', value: 'yellow' },
  { name: 'Violet', value: 'violet' }
]

const setColorTheme = (color: string) => {
  currentTheme.value = color
  localStorage.setItem('user-theme-color', color)
  setTheme({ ...theme.value, color })
}

const getButtonStyle = (color: string) => ({
  backgroundColor: `hsl(var(--${color}))`,
  color: ['yellow', 'green', 'orange', 'stone'].includes(color) ? 'black' : 'white',
  border: 'none'
})

watch(() => theme.value, (newTheme) => {
  currentTheme.value = newTheme.color
}, { deep: true })

onMounted(() => {
  setTheme({ ...theme.value, color: currentTheme.value })
})
</script>

<template>
  <DropdownMenu v-model:open="isOpen">
    <DropdownMenuTrigger asChild>
      <Button size="icon" variant="ghost">
        <Paintbrush class="h-5 w-5" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent class="w-80 p-6 bg-background text-foreground shadow-lg rounded-lg">
      <DropdownMenuLabel class="text-xl font-bold mb-4">Theme Settings</DropdownMenuLabel>
      <p class="text-xs text-muted-foreground mb-6">Customize your interface appearance.</p>
      
      <div class="mb-6">
        <h3 class="font-semibold mb-4 text-sm">Color Palette</h3>
        <div class="grid grid-cols-4 gap-3">
          <Button 
            v-for="theme in colorThemes" 
            :key="theme.value" 
            @click="setColorTheme(theme.value)"
            :class="[
              'h-8 w-full rounded-sm flex items-center justify-center',
              { 'ring-2 ring-ring ring-offset-2 dark:ring-offset-background': currentTheme === theme.value }
            ]"
            :style="getButtonStyle(theme.value)"
          >
            <span class="text-[10px] font-medium">{{ theme.name }}</span>
          </Button>
        </div>
        <p class="mt-4 text-xs font-medium">
          Active: <span class="text-primary font-bold">{{ colorThemes.find(t => t.value === currentTheme)?.name }}</span>
        </p>
      </div>
    </DropdownMenuContent>
  </DropdownMenu>
</template>