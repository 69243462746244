import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/router'
import './assets/index.css'
import { createPinia } from 'pinia'
import GlobalTransition from '@/components/GlobalTransition.vue'
import 'leaflet/dist/leaflet.css'
import Layout from '@/views/Layout.vue'
import { Package2, Box, ScanText, SquareTerminal, Settings } from 'lucide-vue-next'

const app = createApp(App)
app.use(createPinia())
app.use(router).mount('#app')
app.component('GlobalTransition', GlobalTransition)