<template>
  <div>
    <h2 class="text-2xl font-semibold mb-6">Select Software to Install</h2>
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      <div v-for="software in availableSoftware" :key="software.id" class="flex flex-col items-center">
        <button
          @click="toggleSoftware(software.id)"
          :class="[
            'w-full p-4 rounded-lg transition-all duration-200 ease-in-out flex flex-col items-center',
            selectedSoftware.includes(software.id)
              ? 'bg-primary text-primary-foreground shadow-lg'
              : 'bg-card hover:bg-card/80 shadow'
          ]"
        >
          <span class="text-sm font-medium">{{ software.name }}</span>
        </button>
      </div>
    </div>
    <div class="mt-8 p-4 bg-gray-100 rounded-lg">
      <h3 class="text-lg font-semibold mb-2">Current Selections (Debug):</h3>
      <pre class="whitespace-pre-wrap break-words">{{ JSON.stringify(selectedSoftware, null, 2) }}</pre>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { listSoftware } from '@/api/SelfServeAPI'

const softwareList = ref<any[]>([]);

const props = defineProps<{
  selectedSoftware: string[]
}>()

const emit = defineEmits<{
  (e: 'select', value: string[]): void
}>()

const availableSoftware = ref<any[]>([])

onMounted(async () => {
  try {
    const software = await listSoftware()
    if (Array.isArray(software)) {
      availableSoftware.value = software
    } else {
      console.error('Failed to fetch software: Invalid response format')
    }
  } catch (error) {
    console.error('Failed to fetch software:', error)
  }
})

const selected = ref<string[]>(props.selectedSoftware)

const toggleSoftware = (softwareId: string) => {
  const index = selected.value.indexOf(softwareId)
  if (index === -1) {
    selected.value.push(softwareId)
  } else {
    selected.value.splice(index, 1)
  }
  emit('select', selected.value)
}

watch(selected, (newValue) => {
  console.log('Emitting selected software:', newValue)
})

watch(() => props.selectedSoftware, (newValue) => {
  selected.value = newValue
  console.log('Selected software updated:', newValue)
})
</script>