<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@/utils/index'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <div
    :class="
    cn(
      'rounded-lg border bg-card text-card-foreground shadow-sm hover:shadow-lg transition-shadow dark:bg-dark-card',
      props.class,
    )
  "
  >
    <slot />
  </div>
</template>
