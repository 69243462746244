<script setup lang="ts">
import { ref } from 'vue'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Slider } from '@/components/ui/slider'
import { Badge } from '@/components/ui/badge'

const switchState = ref(false)
const sliderValue = ref([50])
</script>

<template>
  <div class="container mx-auto p-8">
    <h1 class="mb-8 text-4xl font-bold">ShadCN Vue Components Showcase</h1>
    
    <Tabs default-value="card1" class="w-full">
      <TabsList class="grid w-full grid-cols-2">
        <TabsTrigger value="card1">Card 1</TabsTrigger>
        <TabsTrigger value="card2">Card 2</TabsTrigger>
      </TabsList>
      <TabsContent value="card1">
        <Card>
          <CardHeader>
            <CardTitle>Interactive Card</CardTitle>
            <CardDescription>Explore various ShadCN Vue components</CardDescription>
          </CardHeader>
          <CardContent>
            <div class="grid gap-6">
              <div class="flex items-center justify-between">
                <Label for="airplane-mode">Airplane Mode</Label>
                <Switch v-model="switchState" id="airplane-mode" />
              </div>
              <div class="space-y-2">
                <Label for="name">Name</Label>
                <Input id="name" placeholder="Enter your name" />
              </div>
              <div class="space-y-2">
                <Label for="slider">Slider</Label>
                <Slider v-model="sliderValue" :max="100" :step="1" />
              </div>
            </div>
          </CardContent>
          <CardFooter class="flex justify-between">
            <Button variant="outline">Cancel</Button>
            <Button>Save Changes</Button>
          </CardFooter>
        </Card>
      </TabsContent>
      <TabsContent value="card2">
        <Card>
          <CardHeader>
            <CardTitle>Statistics</CardTitle>
            <CardDescription>Your activity overview</CardDescription>
          </CardHeader>
          <CardContent>
            <div class="grid gap-4">
              <div class="flex items-center justify-between">
                <span>Total Views</span>
                <Badge>1,234</Badge>
              </div>
              <div class="flex items-center justify-between">
                <span>Likes</span>
                <Badge variant="secondary">567</Badge>
              </div>
              <div class="flex items-center justify-between">
                <span>Comments</span>
                <Badge variant="outline">89</Badge>
              </div>
            </div>
          </CardContent>
          <CardFooter>
            <Button class="w-full">View Detailed Analytics</Button>
          </CardFooter>
        </Card>
      </TabsContent>
    </Tabs>
  </div>
</template>