<template>
    <div class="p-6 bg-white rounded-lg shadow-md">
      <h2 class="text-2xl font-semibold mb-6">Deployment Status</h2>
      <div class="mb-4">
        <Progress :model-value="currentProgress" class="w-full h-6" />
        <p class="mt-2 text-sm text-gray-600">
          <RollingNumber :value="currentProgress" />% Complete
        </p>
      </div>
      <p class="text-lg font-medium">
        Status: 
        <span :class="statusClass">
          {{ capitalizeStatus(deploymentStatus.status) }}
        </span>
      </p>
    </div>
  </template>
  
  <script setup lang="ts">
  import { Progress } from '@/components/ui/progress'
  import RollingNumber from '@/components/ui/RollingNumber.vue'
  import { watch, computed } from 'vue'
  
  interface DeploymentStatus {
    status: 'in-progress' | 'completed' | 'not-started'
    progress: number
  }
  
  const props = defineProps<{
    deploymentStatus: DeploymentStatus
  }>()
  
  const currentProgress = computed(() => props.deploymentStatus.progress)
  
  const statusClass = computed(() => ({
    'text-yellow-500': props.deploymentStatus.status === 'in-progress',
    'text-green-500': props.deploymentStatus.status === 'completed',
    'text-blue-500': props.deploymentStatus.status === 'not-started'
  }))
  
  watch(() => props.deploymentStatus, (newStatus) => {
    console.log('Deployment status updated:', newStatus)
  }, { deep: true })
  
  const capitalizeStatus = (status: string) => {
    return status.charAt(0).toUpperCase() + status.slice(1).replace('-', ' ')
  }
  </script>