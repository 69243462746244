import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Test from '../views/Test.vue'
import SnipeImport from '@/views/SnipeImport.vue'
import Inventaire from '@/views/Inventaire.vue'
import UnderConstruction from '@/views/UnderConstruction.vue'
import SelfServe from '@/views/SelfServe.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/logiciels/selfserve',
    name: 'SelfServe',
    component: SelfServe
  },
  {
    path: '/inventaire/ordinateur',
    component: () => import('@/views/ListeLogicielsOrdinateur.vue'),
  },
  {
    path: '/inventaire/ordinateur/:id',
    component: () => import('@/views/ListeLogicielsOrdinateur.vue'),
    props: true
  },
  {
    path: '/inventaire/starlink',
    component: () => import('@/views/GestionStarlink.vue'),
  },
  {
    path: '/snipeimport',
    name: 'SnipeImport',
    component: SnipeImport
  },
  {
    path: '/inventaire',
    component: () => import('@/views/Inventaire.vue'),
  },
  {
    path: '/inventaire/dispo',
    component: () => import('@/views/InventaireDispo.vue'),
  },
  // Add other routes here
  
  // Catch-all route for non-existent routes
  {
    path: '/:pathMatch(.*)*',
    name: 'UnderConstruction',
    component: UnderConstruction
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router