<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useToast } from '@/components/ui/toast/use-toast'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/ui/card'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell } from '@/components/ui/table'
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@/components/ui/collapsible'
import { FileIcon, AlertCircle, X, Loader2, UploadCloud } from 'lucide-vue-next'
import { Input } from '@/components/ui/input'


// TODO
// 1- Gérer la création du model lorsque le check box est cliqué
//    - Créer le model dans Snipe-IT
//    - Retourner les informations du model (ID, nom) et l'ajouter dans la BD
// 2- Gérer les numéros de série existant. Techniquement ça ne devrait pas arriver mais on sait jamais.
// 3 - Model query caching

const { toast } = useToast()
const errorMessage = ref('');
const files = ref<any[]>([]);
const fileQueue = ref<any[]>([]);
const isDarkMode = ref(false);
const isProcessing = ref(false);
const processedFiles = ref<any[]>([]);
const processingSteps = ['Ready to Upload', 'Claude is thinking..', 'Waiting for Claude', 'Finished'];
const processAllFiles = ref(true); // Default to batch upload
const processOneAtATime = ref(false); // dev tool
const showDevTools = ref(false);
const simulateErrors = ref(false);
const currentFile = ref<any>(null);
const WORKER_URL = import.meta.env.VITE_WORKER_URL || 'https://work-snipe-scan.hamel-construction-inc.workers.dev';
const dragover = ref(false);
const failedAssets = ref<any[]>([]);
const successCount = ref(0);
const showAlert = ref(false);

const updateCreateNewModel = (item: any) => {
  if (!item.hasOwnProperty('createNewModel')) {
    item.createNewModel = false;
  }
  // We don't need to do anything else here, as v-model will handle the update
};

const modelExistenceCache = new Map<string, boolean>();

const checkModelExists = async (asset: any): Promise<boolean> => {
  if (asset.hasOwnProperty('modelNotFound')) {
    return asset.modelNotFound;
  }
  if (modelExistenceCache.has(asset.model)) {
    asset.modelNotFound = !modelExistenceCache.get(asset.model);
    return asset.modelNotFound;
  }
  const { found } = await getModelId(asset.model);
  modelExistenceCache.set(asset.model, found);
  asset.modelNotFound = !found;
  return asset.modelNotFound;
};

// Define the return type for createModel
interface CreateModelResponse {
  success: boolean;
  modelId?: number;
  error?: string;
}

// Define the return type for sendAssetToSnipeIT
interface SendAssetResponse {
  success: boolean;
  error?: string;
  data?: any;
}

const createModel = async (asset: any): Promise<CreateModelResponse> => {
  // This function will be implemented later
  console.log('Create model for:', asset.model);
  // Uncomment and implement the following when ready:
  // try {
  //   const response = await fetch(`${WORKER_URL}/create-model`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       model_number: asset.model,
  //       name: asset.description,
  //       category_id: asset.categoryId,
  //       manufacturer_id: asset.manufacturerId,
  //     }),
  //   });
  //   if (response.ok) {
  //     const newModel = await response.json();
  //     return { success: true, modelId: newModel.id };
  //   } else {
  //     const errorData = await response.json() as { message: string };;
  //     return { success: false, error: errorData.message || 'Failed to create model' };
  //   }
  // } catch (error) {
  //   console.error('Error creating model:', error);
  //   return { success: false, error: error.message };
  // }
  return { success: false, error: 'Not implemented' };
};

// Watch for changes in the processedFiles
watch(() => processedFiles.value, (newProcessedFiles) => {
  newProcessedFiles.forEach(file => {
    file.data.snipeITAssets.forEach(async (asset: any) => {
      if (!asset.hasOwnProperty('modelNotFound')) {
        await checkModelExists(asset);
      }
    });
  });
}, { deep: true });

const buttonBase = "px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2";
const primaryButton = `${buttonBase} bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500`;
const secondaryButton = `${buttonBase} bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-gray-500`;

const editingItem = ref<{ file: any; index: number; data: any } | null>(null);
const startEditing = (processedFile: any, itemIndex: number) => {
  const item = processedFile.data.snipeITAssets[itemIndex];
  editingItem.value = {
    file: processedFile,
    index: itemIndex,
    data: { ...item }
  };
};


const hasError = (processedFile: any, serial: string) => {
  return processedFile.failedAssets.some((failedAsset: any) => failedAsset.asset.serial === serial);
};

const getModelId = async (modelName: string) => {
  console.log(`Attempting to fetch model ID for: "${modelName}"`);
  try {
    const response = await fetch('/api/get-model-id', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ modelName }),
    });
    console.log('Response status:', response.status);
    const headerObj: Record<string, string> = {};
    response.headers.forEach((value, key) => {
      headerObj[key] = value;
    });
    console.log('Response headers:', headerObj);
    
    const responseText = await response.text();
    console.log('Raw response:', responseText);

    if (!response.ok) {
      if (response.status === 404) {
        // Model not found, return a default object
        return { id: null, found: false };
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    let data;
    try {
      data = JSON.parse(responseText);
    } catch (parseError) {
      console.error('Error parsing JSON:', parseError);
      throw new Error('Invalid JSON in response');
    }

    console.log('Parsed response data:', data);
    return data;
  } catch (error) {
    console.error('Error in getModelId:', error);
    // Return a default object in case of any error
    return { id: null, found: false };
  }
};


const checkModelExistence = async (processedFile: any) => {
  for (let item of processedFile.data.snipeITAssets) {
    const { found } = await getModelId(item.model);
    item.modelNotFound = !found;
  }
};

const hasModelNotFound = computed(() => {
  return processedFiles.value.some(file => 
    file.data.snipeITAssets.some((asset: any) => asset.modelNotFound)
  );
});

const handleDrop = (event: DragEvent) => {
  dragover.value = false;
  const droppedFiles = Array.from(event.dataTransfer?.files || []);
  handleFiles(droppedFiles);
};

const toggleDevTools = () => {
  showDevTools.value = !showDevTools.value;
};

const toggleRawJSON = () => {
  showRawJSON.value = !showRawJSON.value;
};

const modifyLine = (processedFile: any, itemIndex: number) => {
  const item = processedFile.data.snipeITAssets[itemIndex];
  const editableData: Record<string, any> = {};
  getHeaders(processedFile).forEach(header => {
    const key = getHeaderKey(header.toLowerCase());
    if (key) {
      editableData[header.toLowerCase()] = item[key] || '';
    }
  });
  editingItem.value = {
    file: processedFile,
    index: itemIndex,
    data: editableData
  };
};

const saveModifiedLine = async () => {
  if (!editingItem.value) return;

  const { file, index, data } = editingItem.value;
  
  // Check if the model exists
  const { found } = await getModelId(data.model);
  data.modelNotFound = !found;

  // Update the asset in the processed file
  file.data.snipeITAssets[index] = { ...data };

  // Reset editing state
  editingItem.value = null;
};

const cancelEditing = () => {
  editingItem.value = null;
};

// Watch for changes in processedFiles and check model existence
watch(processedFiles, async (newFiles) => {
  for (let file of newFiles) {
    await checkModelExistence(file);
  }
}, { deep: true });

const getHeaderKey = (header: string): string => {
  const headerMap: Record<string, string> = {
    'model': 'model',
    'serial number': 'serial',
    'purchase date': 'purchase_date',
    'purchase cost': 'purchase_cost',
    'order number': 'order_number',
    'warranty': 'warranty_months',
    'supplier': 'supplier',
    'category': 'category',
    'status': 'status_id',
    'processor': 'processor',
    'memory': 'memory',
    'storage': 'storage',
    'display': 'display',
    'operating system': 'operating_system'
  };
  return headerMap[header.toLowerCase()] || header.toLowerCase();
};


const fileToBase64 = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File) && !(file instanceof Blob)) {
      console.error('Invalid file object:', file);
      reject(new Error('Invalid file object'));
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result.split(',')[1]);
      } else {
        reject(new Error('Failed to convert file to Base64'));
      }
    };
    reader.onerror = (error) => {
      console.error('FileReader error:', error);
      reject(error);
    };
  });
};

const handleFileUpload = async (event: Event) => {
  const inputElement = event.target as HTMLInputElement;
  if (!inputElement.files) return;

  const newFiles = Array.from(inputElement.files);
  const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  const filteredFiles = newFiles.filter(file => validFileTypes.includes(file.type));

  if (filteredFiles.length !== newFiles.length) {
    console.error('Some files were rejected based on file type');
  }

  files.value = [...files.value, ...filteredFiles];
  fileQueue.value = [...fileQueue.value, ...filteredFiles.map(file => ({
    file,
    progress: 0,
    status: 'queued',
    step: 0
  }))];
};

const handleFiles = async (newFiles: File[]) => {
  console.log('Raw files:', newFiles);

  if (newFiles.length === 0) return;

  const validFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
  const validFiles = newFiles.filter(file => validFileTypes.includes(file.type));

  if (validFiles.length !== newFiles.length) {
    alert('Some files were not added. Only JPEG, PNG, GIF, and WebP files are allowed.');
  }

  files.value = [];
  fileQueue.value = [];

  for (const file of validFiles) {
    console.log('Processing file:', file.name, 'Type:', file.type);
    const fileWithPreview = {
      originalFile: file,
      name: file.name,
      size: file.size,
      type: file.type,
      preview: await createMiniaturePreview(file)
    };
    files.value.push(fileWithPreview);
    fileQueue.value.push({
      file: fileWithPreview,
      progress: 0,
      status: 'queued',
      step: 0
    });
  }

  console.log('Processed files:', files.value);
  console.log('File queue:', fileQueue.value);
};

const createMiniaturePreview = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const scaleFactor = 40 / Math.max(img.width, img.height);
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL());
      };
      img.src = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
};

const processNextFile = async () => {
  if (fileQueue.value.length === 0) {
    isProcessing.value = false;
    return;
  }

  isProcessing.value = true;
  currentFile.value = fileQueue.value[0];
  currentFile.value.status = 'processing';
  errorMessage.value = '';

  console.log('Processing file:', currentFile.value);

  try {
    currentFile.value.step = 0;
    currentFile.value.progress = 25;

    if (!currentFile.value || !currentFile.value.file) {
      console.error('File object is missing:', currentFile.value);
      throw new Error('Invalid file object: file is missing');
    }

    const fileToProcess = currentFile.value.file.originalFile || currentFile.value.file;

    if (!(fileToProcess instanceof File)) {
      console.error('Invalid file object:', fileToProcess);
      throw new Error('Invalid file object: not a File instance');
    }

    console.log('File object to be processed:', fileToProcess);
    const base64Image = await fileToBase64(fileToProcess);

    // Add base64 validation
    if (!/^[A-Za-z0-9+/=]+$/.test(base64Image)) {
      throw new Error('Invalid base64 string');
    }

    console.log(`Base64 image length: ${base64Image.length}`);

    // Step 2: Uploading to Claude
    currentFile.value.step = 1;
    currentFile.value.progress = 50;

    const requestBody = {
      messages: [
        {
          role: "user",
          content: [
            {
              type: "image",
              source: {
                type: "base64",
                media_type: fileToProcess.type,
                data: base64Image
              }
            },
            {
              type: "text",
              text: "Analyze this image and extract relevant information for an IT asset inventory."
            }
          ]
        }
      ]
    };

    const response = await fetch(`${WORKER_URL}/api/claude`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      const errorData = await response.json() as { message: string };;
      throw new Error(`HTTP error! status: ${response.status}, message: ${JSON.stringify(errorData)}`);
    }

    // Step 3: Waiting for Claude
    currentFile.value.step = 2;
    currentFile.value.progress = 75;

    const result = await response.json() as any;;

    // Step 4: Finished
    currentFile.value.step = 3;
    currentFile.value.progress = 100;
    currentFile.value.status = 'completed';

    const processedFile = {
      file: currentFile.value.file,
      data: result
    };

    // Update model IDs and add modelNotFound property
    await updateModelIds(processedFile);

    processedFiles.value.push(processedFile);
  } catch (error: any) {
    console.error('Error processing file:', error);
    currentFile.value.status = 'error';
    currentFile.value.error = error.message;
    errorMessage.value = `Error processing file: ${error.message}`;
  } finally {
    fileQueue.value.shift(); // Remove the processed file from the queue
    setTimeout(() => {
      processNextFile();
    }, 500);
  }
};

const parseFile = async (file: { originalFile: File, base64Image: string }): Promise<any> => {
  try {
    const response = await fetch(`${WORKER_URL}/api/claude`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        image: file.base64Image,
        fileType: file.originalFile.type
      })
    });

    if (!response.ok) {
      const errorData = await response.json() as { message: string };;
      throw new Error(`Server error: ${errorData.message || response.statusText}`);
    }

    const result = await response.json() as any;;

    if (result.fallbackUsed) {
      toast({
        title: "Fallback API Used",
        description: "The main API was unavailable. Results may be less accurate.",
        variant: "warning",
      });
    }

    return result;
  } catch (error) {
    console.error('Error parsing file:', error);
    throw error;
  }
};



const removeProcessedFile = (file: any) => {
  setTimeout(() => {
    fileQueue.value = fileQueue.value.filter(item => item.file !== file);
  }, 5000); // 5 seconds
};

const startProcessing = async () => {
  if (fileQueue.value.length === 0 || isProcessing.value) return;
  
  isProcessing.value = true;
  errorMessage.value = '';
  
  const processFile = async (queueItem: any) => {
    try {
      console.log('Processing file:', queueItem.file);
      
      queueItem.status = 'processing';
      queueItem.progress = 0;

      const fileToProcess = queueItem.file.originalFile || queueItem.file;
      if (!(fileToProcess instanceof File)) {
        throw new Error('Invalid file object');
      }

      // Simulate progress updates
      const updateProgress = (progress: number) => {
        queueItem.progress = progress;
      };

      updateProgress(10);
      const base64Image = await fileToBase64(fileToProcess);
      console.log('File converted to base64');

      updateProgress(30);
      await new Promise(resolve => setTimeout(resolve, 500)); // Simulate some processing time

      updateProgress(60);
      const result = await parseFile({
        originalFile: fileToProcess,
        base64Image: base64Image
      });
      
      updateProgress(100);
      queueItem.status = 'completed';

      // Add to processed files
      processedFiles.value.push({
        file: queueItem.file,
        data: result
      });
      
      // Remove from queue
      fileQueue.value = fileQueue.value.filter(item => item !== queueItem);
    } catch (fileError: any) {
      console.error('Error processing file:', fileError);
      queueItem.status = 'error';
      queueItem.error = fileError.message;
      errorMessage.value = `Error processing ${queueItem.file.name}: ${fileError.message}`;
    }
  };

  const processNextFile = async () => {
    if (fileQueue.value.length === 0) {
      isProcessing.value = false;
      return;
    }

    const nextFile = fileQueue.value[0];
    await processFile(nextFile);

    // Process the next file after a short delay
    setTimeout(() => {
      processNextFile();
    }, 500);
  };

  // Start processing the first file
  processNextFile();
};

// Modify this function to clear only selected files
const clearSelectedFiles = () => {
  files.value = [];
  // Reset the file input
  const fileInput = document.getElementById('dropzone-file') as HTMLInputElement | null;
  if (fileInput) {
    fileInput.value = '';
  }
};

const rawJSONData = computed(() => {
  return processedFiles.value.map(file => ({
    fileName: file.file.name,
    claudeResponse: file.data,
    snipeITAssets: file.data.snipeITAssets
  }));
});

const copyRawJSON = () => {
  const jsonString = JSON.stringify(rawJSONData.value, null, 2);
  navigator.clipboard.writeText(jsonString).then(() => {
    toast({
      title: "Copied to clipboard",
      description: "The raw JSON data has been copied to your clipboard.",
    });
  }).catch(err => {
    console.error('Failed to copy text: ', err);
    toast({
      title: "Copy failed",
      description: "Failed to copy the raw JSON data. Please try again.",
      variant: "destructive",
    });
  });
};

// Function to check system preference
//const checkSystemDarkMode = () => {
//  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
//};

// Initialize dark mode based on system preference
// onMounted(() => {
//   isDarkMode.value = checkSystemDarkMode();
//   applyDarkMode();
// });

// Watch for system preference changes
// watch(
//   () => checkSystemDarkMode(),
//   (newValue) => {
//     isDarkMode.value = newValue;
//     applyDarkMode();
//   }
// );

// Toggle dark mode
// const toggleDarkMode = () => {
//   isDarkMode.value = !isDarkMode.value;
//   applyDarkMode();
// };

// Apply dark mode
const applyDarkMode = () => {
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

const file = ref<File | null>(null);
const equipmentData = ref<any>({
  rawClaudeResponse: { lineItems: [] },
  snipeITAssets: []
});
const showRawJSON = ref(false);
const isLoading = ref(false);

const fileIcon = computed(() => {
  if (!file.value) return null;
  if (file.value.type.startsWith('image/')) return null; // We'll show the actual image preview for images
  if (file.value.type === 'application/pdf') return 'M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z';
  return 'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z';
});

const updateModelIds = async (processedFile: any): Promise<void> => {
  for (const asset of processedFile.data.snipeITAssets) {
    const { id, found } = await getModelId(asset.model);
    asset.modelNotFound = !found;
    asset.model_id = id;
    
        // Initialize createNewModel if model is not found
        if (asset.modelNotFound && !asset.hasOwnProperty('createNewModel')) {
      asset.createNewModel = false;
    }
  }
};


const retryParsing = () => {
  errorMessage.value = ''; // Clear the error message
  if (currentFile.value) {
    processNextFile(); // Retry processing the current file
  } else if (fileQueue.value.length > 0) {
    startProcessing(); // Restart processing if there are files in the queue
  }
};

const getHeaders = (processedFile: any): string[] => {
  const baseHeaders = ['Model', 'Serial Number', 'Purchase Date', 'Purchase Cost', 'Order Number', 'Warranty', 'Supplier', 'Category'];
  const customFields = processedFile.data.snipeITAssets[0]?.custom_fields ? Object.keys(processedFile.data.snipeITAssets[0].custom_fields) : [];
  return [...baseHeaders, ...customFields.map(field => field.charAt(0).toUpperCase() + field.slice(1))];
};


const hasValue = (processedFile: any, header: string): boolean => {
  return processedFile.data.snipeITAssets.some((item: any) => {
    const value = getItemValue(item, header);
    return value && value !== 'N/A';
  });
};

const getStatusName = (statusId: number): string => {
  // You'll need to implement this function to return the status name based on the status ID
  // This could be a simple switch statement or a lookup in a status mapping object
  return 'Unknown Status'; // Placeholder
};

const getItemValue = (item: any, header: string): any => {
  const key = getHeaderKey(header);
  
  if (item.hasOwnProperty(key)) {
    return item[key];
  }
  
  if (item.custom_fields && item.custom_fields.hasOwnProperty(key)) {
    return item.custom_fields[key];
  }
  
  if (key === 'warranty') {
    return item.warranty_months ? `${item.warranty_months} months` : 'N/A';
  }
  
  return 'N/A';
};


const noteFromClaude = (processedFile: any): string | null => {
  if (processedFile.data &&
      processedFile.data.rawClaudeResponse &&
      processedFile.data.rawClaudeResponse.lineItems &&
      processedFile.data.rawClaudeResponse.lineItems.length > 0) {
    return processedFile.data.rawClaudeResponse.lineItems[0].noteFromClaude;
  }
  return null;
};

const prepareSnipeITPayload = async (processedFiles: { data: { snipeITAssets: any[] } }[]): Promise<any[]> => {
  const payload: any[] = [];

  for (const processedFile of processedFiles) {
    for (const asset of processedFile.data.snipeITAssets) {
      const { id: modelId, found } = await getModelId(asset.model);

      // Initialize custom fields
      const customFields: { [key: string]: any } = {};

      // Overwrite defaults with provided data
      if (asset.processor) {
        customFields["_snipeit_cpu_name_15"] = asset.processor;
      }
      if (asset.ram) {
        customFields["_snipeit_computer_memory_14"] = asset.ram;
      }
      if (asset.ssdSize) {
        customFields["_snipeit_disk_drive_size_gb_28"] = asset.ssdSize;
      }
      // Add other fields as necessary

      const preparedAsset = {
        asset_tag: asset.suggestedAssetTag || 'N/A',
        status_id: 26,
        model_id: modelId, // This should now be the correct model ID
        name: asset.name || 'Unknown',
        serial: asset.serial || 'Unknown',
        purchase_date: asset.purchaseDate || 'Unknown',
        purchase_cost: parseFloat(asset.cost.replace('$', '')) || 0,
        order_number: asset.purchaseOrder || 'Unknown',
        notes: `## Uploaded with Snipe Importer w/ Claude AI ##\n${asset.noteFromClaude || ''}`,
        warranty_months: asset.warranty ? parseInt(asset.warranty) : null,
        supplier_id: null,
        rtd_location_id: null,
        ...customFields
      };

      console.log('Prepared asset:', JSON.stringify(preparedAsset, null, 2));
      payload.push(preparedAsset);
    }
  }

  console.log('Prepared payload:', JSON.stringify(payload, null, 2));
  return payload;
};

const sendAssetToSnipeIT = async (asset: any): Promise<SendAssetResponse> => {
  try {
    // Prepare the payload for this asset
    const preparedAsset = {
      asset_tag: asset.suggestedAssetTag || 'N/A',
      status_id: 26,
      model_id: asset.model_id, // This should now be the correct model ID
      name: asset.name || 'Unknown',
      serial: asset.serial || 'Unknown',
      purchase_date: asset.purchaseDate || 'Unknown',
      purchase_cost: parseFloat(asset.cost.replace('$', '')) || 0,
      order_number: asset.purchaseOrder || 'Unknown',
      notes: `## Uploaded with Snipe Importer w/ Claude AI ##\n${asset.noteFromClaude || ''}`,
      warranty_months: asset.warranty ? parseInt(asset.warranty) : null,
      supplier_id: null,
      rtd_location_id: null,
      // Add any other necessary fields here
    };

    const response = await fetch(`${WORKER_URL}/snipe-it-proxy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(preparedAsset),
    });

    if (!response.ok) {
      const errorData = await response.json() as { message: string };;
      return { success: false, error: errorData.message || 'Failed to send asset to Snipe-IT' };
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error: any) {
    console.error('Error sending asset to Snipe-IT:', error);
    return { success: false, error: error.message };
  }
};
const sendToInventory = async (processedFile: { data: { snipeITAssets: any[] }, failedAssets?: { asset: any, error: string }[] }): Promise<void> => {
  isLoading.value = true;
  const failedAssets: { asset: any, error: string }[] = [];
  let localSuccessCount = 0;

  try {
    for (const asset of processedFile.data.snipeITAssets) {
      try {
        if (asset.modelNotFound) {
          if (asset.createNewModel) {
            const modelCreationResponse = await createModel(asset);
            if (modelCreationResponse.success) {
              asset.modelNotFound = false;
              asset.model_id = modelCreationResponse.modelId!;
            } else {
              throw new Error(`Failed to create model: ${modelCreationResponse.error}`);
            }
          } else {
            continue; // Skip assets with not found models if user didn't choose to create
          }
        }

        const response = await sendAssetToSnipeIT(asset);
        if (response.success) {
          localSuccessCount++;
          asset.showSuccessMessage = true;
          
          setTimeout(() => {
            asset.showSuccessMessage = false;
            setTimeout(() => {
              processedFile.data.snipeITAssets = processedFile.data.snipeITAssets.filter((a: any) => a !== asset);
              if (processedFile.data.snipeITAssets.length === 0) {
                processedFiles.value = processedFiles.value.filter((pf: any) => pf !== processedFile);
              } else {
                processedFiles.value = [...processedFiles.value];
              }
            }, 300);
          }, 3000);
        } else {
          failedAssets.push({ asset, error: response.error || 'Unknown error' });
        }
      } catch (error: any) {
        failedAssets.push({ asset, error: error.message || 'Unknown error' });
      }
    }

    // Update failed assets and show messages
    if (failedAssets.length > 0) {
      if (!processedFile.failedAssets) {
        processedFile.failedAssets = [];
      }
      processedFile.failedAssets = [...processedFile.failedAssets, ...failedAssets];
    }

    // If all assets were successful and there are no failed assets, remove the processed file
    if (localSuccessCount === processedFile.data.snipeITAssets.length && failedAssets.length === 0) {
      processedFiles.value = processedFiles.value.filter((pf: any) => pf !== processedFile);
    } else {
      // Trigger reactivity
      processedFiles.value = [...processedFiles.value];
    }

    console.log(`${localSuccessCount} asset(s) sent successfully. ${failedAssets.length} failed.`);
    
    // Show overall success message
    showAlert.value = true;
    successCount.value += localSuccessCount;
    setTimeout(() => {
      showAlert.value = false;
    }, 5000);

  } catch (error: any) {
    console.error('Error in sendToInventory:', error);
    showErrorMessage('An error occurred while sending assets to inventory.');
  } finally {
    isLoading.value = false;
  }
};

const hasAssetsToSend = computed(() => {
  return processedFiles.value.some(file => 
    file.data.snipeITAssets.some((asset: any) => !asset.modelNotFound)
  );
});

const removeFile = (index: number) => {
  files.value.splice(index, 1);
};

const showErrorMessage = (message: string) => {
  console.error(message); // For now, we'll just log to console
  // TODO: Implement a proper error notification system
};

const buttonClasses = "px-6 py-3 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-opacity-50";
const primaryButtonClasses = `${buttonClasses} bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500`;
const secondaryButtonClasses = `${buttonClasses} bg-purple-500 text-white hover:bg-purple-600 focus:ring-purple-500`;
const disabledButtonClasses = "opacity-50 cursor-not-allowed";

const truncateFileName = (fileName: string, maxLength: number = 30): string => {
  if (fileName.length <= maxLength) return fileName;
  const extension = fileName.split('.').pop() || '';
  const nameWithoutExtension = fileName.slice(0, fileName.length - extension.length - (extension ? 1 : 0));
  return `${nameWithoutExtension.slice(0, maxLength - 3 - extension.length)}...${extension}`;
};

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};


const removeFileFromQueue = (index: number) => {
  fileQueue.value.splice(index, 1);
};

const clearFileQueue = () => {
  const delay = 100; // milliseconds between each file removal
  fileQueue.value.forEach((_, index) => {
    setTimeout(() => {
      fileQueue.value.pop();
    }, index * delay);
  });
};

</script>
        





<template>
  <div class="relative min-h-screen">
    <!-- Dev Tools Button -->
    <div class="fixed bottom-5 right-5 z-50">
      <Button 
        @click="toggleDevTools" 
        size="sm"
        variant="outline"
      >
        Dev Tools
      </Button>
    </div>

    <!-- Dev Tools Modal -->
    <Dialog :open="showDevTools" @update:open="showDevTools = $event">
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Dev Tools</DialogTitle>
        </DialogHeader>
        <div class="space-y-4">
          <div class="flex items-center space-x-2">
            <Checkbox v-model="processOneAtATime" id="processOneAtATime" />
            <label for="processOneAtATime">Process one file at a time</label>
          </div>
          <div class="flex items-center space-x-2">
            <Checkbox v-model="simulateErrors" id="simulateErrors" />
            <label for="simulateErrors">Simulate errors</label>
          </div>
          <Button @click="toggleRawJSON" variant="outline" class="mr-2">
            {{ showRawJSON ? 'Hide' : 'Show' }} Raw JSON
          </Button>
          <Button @click="copyRawJSON" variant="outline">
            Copy Raw JSON
          </Button>
          <div v-if="showRawJSON" class="mt-4">
            <pre class="bg-muted p-4 rounded overflow-x-auto max-h-60">{{ JSON.stringify(rawJSONData, null, 2) }}</pre>
          </div>
        </div>
      </DialogContent>
    </Dialog>
    <div class="container mx-auto px-4 py-8">
      <div class="flex flex-col h-full">
        <div class="flex-grow overflow-y-auto">
          <Card class="mb-6">
            <CardHeader>
              <CardTitle>Importer des actifs depuis une facture</CardTitle>
              <CardDescription>Téléchargez des images de factures pour les traiter</CardDescription>
            </CardHeader>
            <CardContent>
              <div
                class="flex flex-col items-center justify-center w-full"
                @dragenter.prevent="dragover = true"
                @dragleave.prevent="dragover = false"
                @dragover.prevent
                @drop.prevent="handleDrop" 
              >
                <label
                  for="dropzone-file"
                  :class="[
                    'flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer transition-colors duration-300',
                    dragover ? 'border-primary bg-primary/5' : 'border-border bg-background',
                    'hover:border-primary hover:bg-primary/5'
                  ]"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                    <UploadCloud class="w-10 h-10 mb-3 text-muted-foreground" />
                    <p class="mb-2 text-sm text-muted-foreground">
                      <span class="font-semibold">Cliquez pour téléverser</span> ou glissez et déposez
                    </p>
                    <p class="text-xs text-muted-foreground">JPEG, PNG, GIF ou WebP (Max 10 MB)</p>
                  </div>
                </label>
                <input id="dropzone-file" type="file" class="hidden" @change="handleFileUpload" accept="image/jpeg,image/png,image/gif,image/webp" multiple />
              </div>

              <!-- Start processing button -->
              <div class="mt-6">
                <Button 
                  @click="startProcessing" 
                  :disabled="fileQueue.length === 0 || isProcessing"
                  class="w-full"
                >
                  <Loader2 v-if="isProcessing" class="mr-2 h-4 w-4 animate-spin" />
                  {{ isProcessing ? 'Traitement en cours...' : 'Démarrer le traitement' }}
                </Button>
              </div>
            </CardContent>
          </Card>

          <!-- Error Message Display -->
          <Alert v-if="errorMessage" variant="destructive">
            <AlertTitle>Error:</AlertTitle>
            <AlertDescription>{{ errorMessage }}</AlertDescription>
            <Button 
              @click="retryParsing" 
              variant="destructive"
              class="mt-2"
            >
              Réessayer
            </Button>
          </Alert>

          <!-- File Upload Queue -->
          <Card class="mb-6">
            <CardHeader>
              <CardTitle>File d'attente</CardTitle>
              <CardDescription>Liste des fichiers en cours de traitement</CardDescription>
            </CardHeader>
            <CardContent>
              <TransitionGroup 
                name="file-list" 
                tag="ul" 
                class="space-y-2"
              >
                <li v-for="(item, index) in fileQueue" :key="item.file.name" class="flex items-center justify-between bg-muted p-2 rounded-md">
                  <div class="flex items-center">
                    <FileIcon class="w-5 h-5 mr-2 text-muted-foreground" />
                    <span class="text-sm">{{ truncateFileName(item.file.name, 20) }}</span>
                  </div>
                  <div class="flex items-center">
                    <div v-if="item.status === 'processing'" class="mr-2 relative">
                      <Loader2 class="h-4 w-4 animate-spin" />
                    </div>
                    <span v-else class="text-xs text-muted-foreground mr-2">{{ item.status }}</span>
                    <AlertCircle v-if="item.error" class="w-4 h-4 text-destructive mr-2" />
                    <Button v-if="!isProcessing" variant="ghost" size="icon" @click="removeFileFromQueue(index)">
                      <X class="h-4 w-4" />
                    </Button>
                  </div>
                </li>
              </TransitionGroup>
              <div v-if="fileQueue.length === 0" class="text-sm text-muted-foreground">Aucun fichier en attente</div>
              <Button 
                v-if="fileQueue.length > 0" 
                @click="clearFileQueue" 
                variant="outline" 
                class="mt-4"
              >
                Clear All
              </Button>
            </CardContent>
          </Card>

          <!-- Processed Files -->
          <transition-group name="fade-slide" tag="div" class="space-y-6">
            <div v-for="(processedFile, fileIndex) in processedFiles" :key="processedFile.file.name" class="mt-6">
              <Card v-if="processedFile.data.snipeITAssets.length > 0 || processedFile.failedAssets?.length > 0">
                <!-- ... CardHeader and notes section ... -->
                <CardContent>
                  <div class="overflow-x-auto">
                    <div class="inline-block min-w-full align-middle">
                      <div class="overflow-hidden border rounded-lg">
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead class="w-[100px] sticky left-0 bg-background z-10">Actions</TableHead>
                              <TableHead v-for="header in getHeaders(processedFile)" :key="header" class="whitespace-nowrap">{{ header }}</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            <TableRow v-for="(item, itemIndex) in processedFile.data.snipeITAssets" :key="itemIndex">
                              <TableCell class="w-[100px] sticky left-0 bg-background z-10">
                                <template v-if="editingItem && editingItem.file === processedFile && editingItem.index === itemIndex">
                                  <div class="flex space-x-2">
                                    <Button @click="saveModifiedLine" variant="default" size="sm">Save</Button>
                                    <Button @click="cancelEditing" variant="secondary" size="sm">Cancel</Button>
                                  </div>
                                </template>
                                <template v-else>
                                  <Button @click="startEditing(processedFile, itemIndex)" variant="secondary" size="sm">Modify</Button>
                                </template>
                              </TableCell>
                              <TableCell 
                                v-for="header in getHeaders(processedFile)" 
                                :key="header"
                                :class="[
                                  'whitespace-nowrap overflow-hidden text-ellipsis',
                                  { 'bg-red-100 dark:bg-red-900': header.toLowerCase() === 'model' && item.modelNotFound }
                                ]"
                              >
                                <template v-if="editingItem && editingItem.file === processedFile && editingItem.index === itemIndex">
                                  <Input 
                                    v-model="editingItem.data[getHeaderKey(header)]"
                                    class="w-full"
                                  />
                                </template>
                                <template v-else-if="header.toLowerCase() === 'model' && item.modelNotFound">
                                  <div class="flex items-center">
                                    <AlertCircle class="w-4 h-4 mr-2 text-red-500 flex-shrink-0" />
                                    <span class="truncate">{{ getItemValue(item, header) }}</span>
                                  </div>
                                </template>
                                <template v-else>
                                  <span class="truncate">{{ getItemValue(item, header) }}</span>
                                </template>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </CardContent>
                <CardFooter>
                  <Button
                    @click="sendToInventory(processedFile)"
                    :disabled="!processedFile || (processedFile.data.snipeITAssets.length === 0 && !processedFile.failedAssets?.length)"
                  >
                    Envoyer à l'inventaire
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>






<style scoped>

.file-list-enter-active,
.file-list-leave-active {
  transition: all 0.5s ease;
}
.file-list-enter-from,
.file-list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.file-list-move {
  transition: transform 0.5s ease;
}


.absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.p-4 {
  padding: 1rem;
}
.z-50 {
  z-index: 50;
}

.h-screen {
  height: 100vh;
}
.w-screen {
  width: 100vw;
}
.container {
  max-width: none;
}
.h-full {
  height: 100%;
}
.overflow-y-auto {
  overflow-y: auto;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.5s ease;
}
.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.suggested-category {
  @apply bg-yellow-50 dark:bg-yellow-900;
}
.suggested-category:hover {
  @apply bg-yellow-100 dark:bg-yellow-800;
}
.fade-slide-appear-active {
  transition: all 0.5s ease;
}
.fade-slide-appear-from {
  opacity: 0;
  transform: translateY(20px);
}

.list-disc {
  list-style-type: disc;
}
.list-inside {
  list-style-position: inside;
}

.file-list-enter-active,
.file-list-leave-active {
  transition: all 0.5s ease;
}
.file-list-enter-from,
.file-list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.file-list-move {
  transition: transform 0.5s ease;
}

.no-select {
  user-select: none;
}


.whitespace-nowrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>                 
