<script setup lang="ts">
import { ref } from 'vue'
import { ChevronRight, LayoutDashboard, Settings, FileText, HelpCircle, Laptop, Printer, Bell } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

const quickActions = [
  { name: 'Inventory', icon: Laptop, description: 'Manage your IT assets' },
  { name: 'Support', icon: HelpCircle, description: 'Create or view support tickets' },
  { name: 'Reports', icon: FileText, description: 'Generate and view reports' },
  { name: 'Settings', icon: Settings, description: 'Configure system settings' },
]

const recentActivities = ref([
  { description: 'New laptop added to inventory', timestamp: '2 hours ago' },
  { description: 'Support ticket #1234 resolved', timestamp: '1 day ago' },
  { description: 'Software update deployed', timestamp: '2 days ago' },
])

const stats = ref([
  { label: 'Total Devices', value: 1250, icon: Laptop },
  { label: 'Available Laptops', value: 45, icon: Laptop },
  { label: 'Printers', value: 30, icon: Printer },
  { label: 'Active Tickets', value: 12, icon: HelpCircle },
])
</script>

<template>
  <div class="container mx-auto p-6 space-y-8">
    <header class="flex justify-between items-center mb-8">
      <h1 class="text-4xl font-bold">Salut mon poulet! 🐔</h1>
      <div class="flex items-center space-x-4">
      </div>
    </header>

    <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
      <Card v-for="stat in stats" :key="stat.label">
        <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle class="text-sm font-medium">{{ stat.label }}</CardTitle>
          <component :is="stat.icon" class="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div class="text-2xl font-bold">{{ stat.value }}</div>
        </CardContent>
      </Card>
    </div>

    <div class="grid gap-6 md:grid-cols-2">
      <Card>
        <CardHeader>
          <CardTitle>Quick Actions</CardTitle>
          <CardDescription>Access frequently used features</CardDescription>
        </CardHeader>
        <CardContent class="grid gap-4 sm:grid-cols-2">
          <Button v-for="action in quickActions" :key="action.name" variant="outline" class="h-24 justify-start">
            <component :is="action.icon" class="mr-2 h-5 w-5" />
            <div class="text-left">
              <div class="font-semibold">{{ action.name }}</div>
              <div class="text-xs text-muted-foreground">{{ action.description }}</div>
            </div>
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Recent Activities</CardTitle>
          <CardDescription>Latest updates and actions</CardDescription>
        </CardHeader>
        <CardContent>
          <ul class="space-y-4">
            <li v-for="(activity, index) in recentActivities" :key="index" class="flex justify-between items-center text-sm">
              <span>{{ activity.description }}</span>
              <span class="text-muted-foreground">{{ activity.timestamp }}</span>
            </li>
          </ul>
        </CardContent>
        <CardFooter>
          <Button variant="ghost" class="w-full">View All Activities</Button>
        </CardFooter>
      </Card>
    </div>
  </div>
</template>