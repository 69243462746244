<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { PanelLeft, Settings, Sun, Moon } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import HelpTicketForm from './HelpTicketForm.vue'
import UserMenu from './UserMenu.vue'
import MainNav from './MainNav.vue'
import CustomizeMenuWithTrigger from './CustomizeMenuWithTrigger.vue'
import { useTheme } from '../composables/useTheme'

const isOpen = ref(false)
const { theme, setTheme } = useTheme()
const isDarkMode = ref(false)

const toggleTheme = () => {
  isDarkMode.value = !isDarkMode.value
  applyTheme()
}

const applyTheme = () => {
  setTheme({ ...theme.value, dark: isDarkMode.value })
  localStorage.setItem('user-theme-mode', isDarkMode.value ? 'dark' : 'light')
}

const updateThemeFromSystem = () => {
  isDarkMode.value = window.matchMedia('(prefers-color-scheme: dark)').matches
  applyTheme()
}

watch(() => theme.value.dark, (newDarkValue) => {
  isDarkMode.value = newDarkValue
})

onMounted(() => {
  const savedTheme = localStorage.getItem('user-theme-mode')
  if (savedTheme === 'dark' || savedTheme === 'light') {
    isDarkMode.value = savedTheme === 'dark'
  } else {
    updateThemeFromSystem()
  }
  applyTheme()

  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  mediaQuery.addEventListener('change', updateThemeFromSystem)

  return () => {
    mediaQuery.removeEventListener('change', updateThemeFromSystem)
  }
})
</script>

<template>
  <header class="sticky top-0 z-30 flex h-12 items-center border-b bg-background w-full p-2 pr-3 shadow-sm">
    <div class="flex items-center justify-between w-full px-1">
      <!-- Mobile menu trigger -->
      <Sheet v-model:open="isOpen">
        <SheetTrigger as-child>
          <Button size="icon" variant="outline" class="sm:hidden">
            <PanelLeft class="h-5 w-5" />
            <span class="sr-only">Toggle Menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" class="sm:max-w-xs">
          <nav class="grid gap-6 text-lg font-medium">
            <MainNav />
          </nav>
        </SheetContent>
      </Sheet>

      <!-- Main Navigation -->
      <div class="hidden sm:block">
        <MainNav />
      </div>

      <!-- Other buttons -->
      <div class="flex items-center gap-1">
        <HelpTicketForm />
        <Button size="icon" variant="ghost" @click="toggleTheme">
          <Sun v-if="isDarkMode" class="h-5 w-5" />
          <Moon v-else class="h-5 w-5" />
        </Button>
        <CustomizeMenuWithTrigger />
        <Button size="icon" variant="ghost" to="/settings">
          <Settings class="h-5 w-5" />
        </Button>
        <UserMenu />
      </div>
    </div>
  </header>
</template>