<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
} from '@/components/ui/navigation-menu'
import { navigationMenuTriggerStyle } from '@/components/ui/navigation-menu'

const router = useRouter()

const SnipeIT = () => {
  window.open('https://snipe-it.hamelconstruction.com', '_blank')
}

const inventoryItems = [
  { label: 'Aperçu global', route: '/inventaire', description: 'Vue d\'ensemble de l\'inventaire' },
  { label: 'Disponible', route: '/inventaire/dispo', description: 'Voir les équipements disponibles' },
  { label: '🔗 Snipe-IT', action: SnipeIT, description: 'Accéder à Snipe-IT' },
  { label: 'Information ordinateur', route: '/inventaire/ordinateur', description: 'Voir les spécifications, logiciels installés, informations d\'dachat et autre..'}
  
]
</script>

<template>
  <NavigationMenu>
    <NavigationMenuList>
      <NavigationMenuItem>
        <NavigationMenuLink :as-child="true">
          <router-link :to="'/'" :class="navigationMenuTriggerStyle()">
            Accueil
          </router-link>
        </NavigationMenuLink>
      </NavigationMenuItem>
      
      <NavigationMenuItem>
        <NavigationMenuTrigger>
          Inventaire
        </NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid gap-3 p-4 w-[400px] lg:w-[800px] lg:grid-cols-[repeat(auto-fill,minmax(250px,1fr))]">
            <li class="lg:col-span-1">
              <NavigationMenuLink as-child>
                <router-link
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground bg-gradient-to-br from-purple-400 via-blue-600 to-indigo-400"
                  to="/snipeimport"
                >
                  <div class="text-sm font-medium leading-none text-white">Importer comme un G.O.A.T 🐐</div>
                  <p class="line-clamp-2 text-sm leading-snug text-white/90 mt-1">
                    Importation automatique des équipements provenant de facture fournisseur
                  </p>
                </router-link>
              </NavigationMenuLink>
            </li>
            <li v-for="item in inventoryItems" :key="item.label" class="lg:col-span-1">
              <NavigationMenuLink :as-child="true">
                <component
                  :is="item.route ? 'router-link' : 'a'"
                  :to="item.route"
                  :href="item.route ? undefined : '#'"
                  @click="item.action ? item.action() : undefined"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground h-full"
                >
                  <div class="flex items-center">
                    <div class="text-sm font-medium leading-none">{{ item.label }}</div>
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground mt-1">
                    {{ item.description }}
                  </p>
                </component>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
      <NavigationMenuItem>
        <NavigationMenuTrigger>
          Outils
        </NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid gap-3 p-4 w-[400px]">
            <li>
              <NavigationMenuLink :as-child="true">
                <router-link
                  to="/logiciels/selfserve"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="text-sm font-medium leading-none">👷‍♂️🔧 Self-Serve</div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground mt-1">
                    ( En construction ) Installer des logiciels sur votre ordinateur
                  </p>
                </router-link>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
    
    </NavigationMenuList>
  </NavigationMenu>
</template>