<template>
    <div class="container mx-auto p-4 max-w-4xl">
      <h1 class="text-3xl font-bold mb-6 text-center">Software Self-Serve Portal</h1>
      <div class="bg-white shadow-md rounded-lg p-6">
        <Stepper v-model="currentStep" class="mb-8">
          <StepperItem v-for="(step, index) in steps" :key="index" :step="index">
            <StepperTrigger>
              <StepperTitle>{{ step.title }}</StepperTitle>
              <StepperDescription>{{ step.description }}</StepperDescription>
            </StepperTrigger>
          </StepperItem>
        </Stepper>
        
        <div class="mt-8">
          <Button @click="testDeployment" :disabled="isDeploying">
            {{ isDeploying ? 'Deploying...' : 'Test Deployment' }}
          </Button>
        </div>
  
        <div v-if="deploymentResult" class="mt-4 p-4 bg-gray-100 rounded-lg">
          <h3 class="text-lg font-semibold mb-2">Deployment Result:</h3>
          <pre class="whitespace-pre-wrap break-words">{{ JSON.stringify(deploymentResult, null, 2) }}</pre>
        </div>
  
        <div class="mb-8">
          <component
            :is="steps[currentStep].component"
            :selectedComputer="selectedComputer"
            :selectedSoftware="selectedSoftware"
            :deploymentStatus="deploymentStatus"
            @select="handleSelect"
            @deploy="deploySoftware"
          />
        </div>
  
        <div class="flex justify-between">
          <Button @click="prevStep" :disabled="currentStep === 0">Previous</Button>
          <Button @click="nextStep" :disabled="!canProceed || currentStep === steps.length - 1">
            {{ currentStep === steps.length - 1 ? 'Finish' : 'Next' }}
          </Button>
        </div>
      </div>
      <div class="mt-8 p-4 bg-gray-100 rounded-lg">
        <h3 class="text-lg font-semibold mb-2">Current State (Debug):</h3>
        <pre class="whitespace-pre-wrap break-words">{{ debugState }}</pre>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref, computed, watch } from 'vue'
  import { Stepper, StepperItem, StepperTrigger, StepperTitle, StepperDescription } from '@/components/ui/stepper'
  import { Button } from '@/components/ui/button'
  import ComputerSelection from '@/views/ComputerSelection.vue'
  import SoftwareSelection from '@/views/SoftwareSelection.vue'
  import Overview from '@/views/Overview.vue'
  import StatusPage from '@/views/StatusPage.vue'

  const WORKER_URL = import.meta.env.VITE_WORKER_URL || 'https://work-snipe-scan.hamel-construction-inc.workers.dev'
  
  const debugState = computed(() => JSON.stringify({
    currentStep: currentStep.value,
    selectedComputer: selectedComputer.value,
    selectedSoftware: selectedSoftware.value,
    deploymentStatus: deploymentStatus.value
  }, null, 2))
  
  interface DeploymentResult {
  id?: string;
  name?: string;
  packageId?: string;
  endpointIds?: string[];
  scheduleType?: string;
  error?: string;
}

  interface DeploymentStatus {
    status: 'in-progress' | 'completed' | 'not-started'
    progress: number
  }
  
  const currentStep = ref(0)
  const selectedComputer = ref<string | null>(null)
  const selectedSoftware = ref<string[]>([])
  const deploymentStatus = ref<DeploymentStatus>({ status: 'not-started', progress: 0 })
  const isDeploying = ref(false)
  const deploymentResult = ref<DeploymentResult | null>(null)
  
  const steps = [
    { title: 'Select Computer', description: 'Choose your computer', component: ComputerSelection },
    { title: 'Select Software', description: 'Choose software to install', component: SoftwareSelection },
    { title: 'Overview', description: 'Review and deploy', component: Overview },
    { title: 'Status', description: 'Deployment progress', component: StatusPage },
  ]
  
  const canProceed = computed(() => {
    switch (currentStep.value) {
      case 0:
        return selectedComputer.value !== null
      case 1:
        return selectedSoftware.value.length > 0
      case 2:
        return true
      default:
        return false
    }
  })
  
  const handleSelect = (value: string | string[]) => {
    if (typeof value === 'string') {
      selectedComputer.value = value
      console.log('Selected computer:', value)
    } else {
      selectedSoftware.value = value
      console.log('Selected software:', value)
    }
  }
  
  const nextStep = () => {
    if (currentStep.value < steps.length - 1 && canProceed.value) {
      currentStep.value++
    }
  }
  
  const prevStep = () => {
    if (currentStep.value > 0) {
      currentStep.value--
    }
  }
  
  const deploySoftware = () => {
    deploymentStatus.value = { status: 'in-progress', progress: 0 }
    currentStep.value++
  
    const interval = setInterval(() => {
      deploymentStatus.value.progress += 10
      if (deploymentStatus.value.progress >= 100) {
        clearInterval(interval)
        deploymentStatus.value.status = 'completed'
      }
    }, 1000)
  }
  
  const testDeployment = async () => {
  isDeploying.value = true
  try {
    const response = await fetch(`${WORKER_URL}/api/selfserve/push-deployment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: "Deploy HandBrake",
        packageId: "GPL_v2_HandBrake_1632064049587_builtin",
        endpointId: "3cbf6151-8bc4-4626-8325-6937df1114d4",
        scheduleType: "RunNow"
      })
    })

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const result = await response.json() as DeploymentResult;
    deploymentResult.value = result;
    console.log('Deployment result:', result)
  } catch (error) {
    console.error('Error during deployment:', error)
    deploymentResult.value = { error: error instanceof Error ? error.message : String(error) }
  } finally {
    isDeploying.value = false
  }
}

  
  watch(selectedComputer, (newValue: string | null) => {
    console.log('selectedComputer updated:', newValue)
  })
  
  watch(selectedSoftware, (newValue: string[]) => {
    console.log('selectedSoftware updated:', newValue)
  })
  </script>