<script setup lang="ts">
import Layout from '@/views/Layout.vue'
import { useRoute } from 'vue-router'
import { Toaster } from '@/components/ui/sonner'
import 'leaflet/dist/leaflet.css'

const route = useRoute()
</script>

<template>
  <Layout class="min-h-screen">
    <template #main>
      <div class="w-full">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </template>
  </Layout>
  <Toaster />
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease, transform 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(3px);
}
</style>