<template>
    <span>{{ displayNumber }}</span>
</template>

  <script setup lang="ts">
  import { ref, watch, onMounted } from 'vue'
  
  const props = defineProps<{
    value: number
  }>()
  
  const displayNumber = ref(0)
  const duration = 1000 // Animation duration in milliseconds
  
  const animateValue = (start: number, end: number, duration: number) => {
    let startTimestamp: number | null = null
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp
      const progress = Math.min((timestamp - startTimestamp) / duration, 1)
      displayNumber.value = Math.floor(progress * (end - start) + start)
      if (progress < 1) {
        window.requestAnimationFrame(step)
      }
    }
    window.requestAnimationFrame(step)
  }
  
  watch(() => props.value, (newValue) => {
    animateValue(displayNumber.value, newValue, duration)
  })
  
  onMounted(() => {
    animateValue(0, props.value, duration)
  })
  </script>