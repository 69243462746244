<script setup lang="ts">
import { ref } from 'vue'
import { HelpCircle } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from '@/components/ui/drawer'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useMediaQuery } from '@vueuse/core'
import { useToast } from '@/components/ui/toast'

const isDesktop = useMediaQuery('(min-width: 768px)')
const isOpen = ref(false)
const { toast } = useToast()

const ticketForm = ref({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneExtension: '',
  category: '',
  subject: '',
  description: ''
})

const submitTicket = async () => {
  // Implement ticket submission logic
}
</script>

<template>
  <Dialog v-if="isDesktop" v-model:open="isOpen">
    <!-- Dialog content -->
  </Dialog>

  <Drawer v-else v-model:open="isOpen">
    <!-- Drawer content -->
  </Drawer>
</template>