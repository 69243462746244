<script setup lang="ts">
import { ref } from 'vue'
import Header from '@/views/Header.vue'

const sidebarExpanded = ref(false)

const handleSidebarToggle = (expanded: boolean) => {
  sidebarExpanded.value = expanded
}
</script>

<template>
  <div class="min-h-screen bg-background dark:bg-dark-background flex flex-col">
    <Header class="w-full" />
    <div class="flex flex-col flex-1">
      <main class="flex-1 overflow-auto">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </main>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease, transform 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(3px);
}
</style>