const WORKER_URL = import.meta.env.VITE_WORKER_URL || 'https://work-snipe-scan.hamel-construction-inc.workers.dev';

export const listComputers = async () => {
  try {
    const response = await fetch(`${WORKER_URL}/api/selfserve/list-computers`);
    if (!response.ok) {
      throw new Error('Failed to fetch computers');
    }
    return await response.json();
  } catch (err) {
    throw new Error(err instanceof Error ? err.message : 'An error occurred while fetching computers');
  }
};

export const listSoftware = async () => {
  try {
    const response = await fetch(`${WORKER_URL}/api/selfserve/list-software`);
    if (!response.ok) {
      throw new Error('Failed to fetch software');
    }
    return await response.json();
  } catch (err) {
    throw new Error(err instanceof Error ? err.message : 'An error occurred while fetching software');
  }
};


export const pushDeployment = async (deploymentData: any) => {
  try {
    const response = await fetch(`${WORKER_URL}/api/selfserve/push-deployment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(deploymentData),
    });
    if (!response.ok) {
      throw new Error('Failed to push deployment');
    }
    return await response.json();
  } catch (err) {
    throw new Error(err instanceof Error ? err.message : 'An error occurred while pushing deployment');
  }
};

export const getDeploymentStatus = async (deploymentId: string) => {
  try {
    const response = await fetch(`${WORKER_URL}/api/selfserve/get-deployment-status?id=${deploymentId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch deployment status');
    }
    return await response.json();
  } catch (err) {
    throw new Error(err instanceof Error ? err.message : 'An error occurred while fetching deployment status');
  }
};