import { ref, watch } from 'vue'

interface Theme {
  color: string
  dark: boolean
}

const theme = ref<Theme>({
  color: 'zinc',
  dark: false
})

export function useTheme() {
  const setTheme = (newTheme: Theme) => {
    theme.value = newTheme
  }

  watch(theme, (newTheme) => {
    requestAnimationFrame(() => {
      document.documentElement.classList.remove('light', 'dark');
      document.documentElement.classList.add(newTheme.dark ? 'dark' : 'light');
      document.body.setAttribute('data-theme', newTheme.color);
    });
  }, { immediate: true, deep: true })

  return {
    theme,
    setTheme
  }
}