<template>
    <div>
      <h2 class="text-xl font-semibold mb-4">Select Your Computer</h2>
      <Select v-model="selectedComputer" @update:modelValue="$emit('select', $event)">
        <SelectTrigger class="w-[180px]">
          <SelectValue placeholder="Select a computer" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="laptop-001">Laptop 001</SelectItem>
          <SelectItem value="desktop-002">Desktop 002</SelectItem>
          <SelectItem value="workstation-003">Workstation 003</SelectItem>
        </SelectContent>
      </Select>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue'
  import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
  
  const selectedComputer = ref<string | undefined>(undefined)
  
  defineEmits<{
    (e: 'select', value: string): void
  }>()
  </script>